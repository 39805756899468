import Aos from 'aos';
import '../src/assets/styles/App.css';
import '../src/assets/styles/media.css';
import AboutMobile from './components/AboutMobile';
import Food from './components/Food';
import Footer from './components/Footer';
import Funk from './components/Funkshions';
import Header from './components/Header';
import Section from './components/Section';

function App() {
  Aos.init();
  return (
    <div className="App">
      <Header/>
      <Section/>
      <AboutMobile/>
      <Food/>
      <Funk/>
      <Footer/>
    </div>
  );
}

export default App;
