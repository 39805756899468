import React from "react";
import logo from "./../assets/image/logo_footer.png";
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";
import { FaTelegramPlane } from "react-icons/fa";

export default function Footer() {
  return (
    <div id="footer">
      <div className="container">
        <div className="footer">
          <img className="logo_footer" src={logo} alt="" />
          <div className="instagram">
            <div className="Advantages">
              <p>О приложении</p>
              <p>Преимущества</p>
              <p>Больше информации</p>
            </div>
            <div className="IoLogoYoutube">
              <FaInstagram size={25} color="#fff" />
              <FaWhatsapp size={25} color="#fff" />
              <FaTelegramPlane size={25} color="#fff" />
              <IoLogoYoutube size={25} color="#fff" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
