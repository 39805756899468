// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../node_modules/aos/dist/aos.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
}
:root{
  background-color: #FBFBFB;
}
.container{
  width: 90%;
  max-width: 1140px;
  margin: 10px auto;
}
button{
  padding: 12px;
  border-radius: 35px;
  background-color: #ECECEC;
  color: 13px;
  font-weight: 600;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/index.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;EACT,sBAAsB;EACtB,8BAA8B;AAChC;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,UAAU;EACV,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,WAAW;EACX,gBAAgB;EAChB,YAAY;AACd","sourcesContent":["*{\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n  font-family: Inter, sans-serif;\n}\n@import \"aos/dist/aos.css\";\n:root{\n  background-color: #FBFBFB;\n}\n.container{\n  width: 90%;\n  max-width: 1140px;\n  margin: 10px auto;\n}\nbutton{\n  padding: 12px;\n  border-radius: 35px;\n  background-color: #ECECEC;\n  color: 13px;\n  font-weight: 600;\n  border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
