import React from "react";
import hpone from "./../assets/image/iPhone16pro.png"
import googleplay from "./../assets/image/googleplay.png"
import AppStore  from "./../assets/image/AppStore.png"
export default function Section() {
  return (
    <div id="sections">
      <div className="container">
        <div className="section">
          <div className="product">
            <h1>Доставка продуктов до вашего дома</h1>
            <p>Скачать приложение сейчас</p>
            <div className="mobile">
                <img src={googleplay} alt="" />
                <img src={AppStore } alt="" />
            </div>
          </div>
            <img className="phone16pro" src={hpone} alt="" />
        </div>
      </div>
    </div>
  );
}
