// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Inter_18pt-ExtraLight.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Inter_18pt-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Inter_18pt-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/Inter_18pt-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/Inter_18pt-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../fonts/Inter_18pt-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../fonts/Inter_18pt-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../fonts/Inter_18pt-Black.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: Inter, sans-serif;
      src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
      font-weight: 200;
    }
    
    @font-face {
        font-family: Inter, sans-serif;
      src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
      font-weight: 300;
    }
    
    @font-face {
        font-family: Inter, sans-serif;
      src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
      font-weight: 400;
    }
    
    @font-face {
        font-family: Inter, sans-serif;
      src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
      font-weight: 500;
    }
    
    @font-face {
        font-family: Inter, sans-serif;
      src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
      font-weight: 600;
    }
    
    @font-face {
        font-family: Inter, sans-serif;
      src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
      font-weight: 700;
    }
    
    @font-face {
        font-family: Inter, sans-serif;
      src: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
      font-weight: 800;
    }
   
    @font-face {
        font-family: Inter, sans-serif;
      src: url(${___CSS_LOADER_URL_REPLACEMENT_7___});
      font-weight: 900;
    }`, "",{"version":3,"sources":["webpack://./src/assets/styles/fonts.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;MAC5B,4CAA8C;MAC9C,gBAAgB;IAClB;;IAEA;QACI,8BAA8B;MAChC,4CAAyC;MACzC,gBAAgB;IAClB;;IAEA;QACI,8BAA8B;MAChC,4CAA2C;MAC3C,gBAAgB;IAClB;;IAEA;QACI,8BAA8B;MAChC,4CAA0C;MAC1C,gBAAgB;IAClB;;IAEA;QACI,8BAA8B;MAChC,4CAA4C;MAC5C,gBAAgB;IAClB;;IAEA;QACI,8BAA8B;MAChC,4CAAwC;MACxC,gBAAgB;IAClB;;IAEA;QACI,8BAA8B;MAChC,4CAA6C;MAC7C,gBAAgB;IAClB;;IAEA;QACI,8BAA8B;MAChC,4CAAyC;MACzC,gBAAgB;IAClB","sourcesContent":["@font-face {\n    font-family: Inter, sans-serif;\n      src: url(\"../fonts/Inter_18pt-ExtraLight.ttf\");\n      font-weight: 200;\n    }\n    \n    @font-face {\n        font-family: Inter, sans-serif;\n      src: url(\"../fonts/Inter_18pt-Light.ttf\");\n      font-weight: 300;\n    }\n    \n    @font-face {\n        font-family: Inter, sans-serif;\n      src: url(\"../fonts/Inter_18pt-Regular.ttf\");\n      font-weight: 400;\n    }\n    \n    @font-face {\n        font-family: Inter, sans-serif;\n      src: url(\"../fonts/Inter_18pt-Medium.ttf\");\n      font-weight: 500;\n    }\n    \n    @font-face {\n        font-family: Inter, sans-serif;\n      src: url(\"../fonts/Inter_18pt-SemiBold.ttf\");\n      font-weight: 600;\n    }\n    \n    @font-face {\n        font-family: Inter, sans-serif;\n      src: url(\"../fonts/Inter_18pt-Bold.ttf\");\n      font-weight: 700;\n    }\n    \n    @font-face {\n        font-family: Inter, sans-serif;\n      src: url(\"../fonts/Inter_18pt-ExtraBold.ttf\");\n      font-weight: 800;\n    }\n   \n    @font-face {\n        font-family: Inter, sans-serif;\n      src: url(\"../fonts/Inter_18pt-Black.ttf\");\n      font-weight: 900;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
