import React, { useState, useEffect } from "react";
import food from "./../assets/image/food.png";
import tomato from "./../assets/image/tomato.png";
import milk from "./../assets/image/milk.png";
import Clippathgroup from "./../assets/image/Clippathgroup.png";
import two_phone from "./../assets/image/two_phone.png";

export default function Food() {
  const [scrollDirection, setScrollDirection] = useState(0);

  const handleScroll = (event) => {
    const deltaY = event.deltaY;
    setScrollDirection(deltaY);
  };

  useEffect(() => {
    window.addEventListener("wheel", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, []);

  return (
    <div className="container">
      <div className="food">
        <div className="food-image">
          <div
            className="red_food"
            style={{
              transform: `translateY(${scrollDirection > 0 ? "50px" : "0"})`,
              transition: "transform 0.5s ease",
            }}
          >
            <img src={tomato} alt="" />
            <div className="sale">
              <p>-30%</p>
            </div>
            <div className="detail">
              <div className="prices">
                <p className="price">90c</p>
                <p className="strikethrough">100с</p>
              </div>
              <h6>Красные томаты Эко</h6>
              <button className="add">Добавить</button>
            </div>
          </div>
          <img src={food} alt="" />
          <div
            className="red_foods"
            style={{
              transform: `translateY(${scrollDirection < 0 ? "50px" : "0"})`,
              transition: "transform 0.5s ease",
            }}
          >
            <img src={milk} alt="" />
            <div className="sale">
              <p>-30%</p>
            </div>
            <div className="detail">
              <div className="prices">
                <p className="price">90c</p>
                <p className="strikethrough">100с</p>
              </div>
              <h6>Молоко пастеризован...</h6>
              <button className="add">Добавить</button>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="koled">
            <div className="bonus">
              <h1>
                Заказывай доставку свежих продуктов на “Koleso”, и получай
                кэшбек 5%
              </h1>
              <p>
                После оплаты покупки, вам начисляются бонусы, <br /> которыми вы
                можете воспользоваться при следующей покупке!
              </p>
            </div>
          </div>
          <img className="Clippathgroup" src={Clippathgroup} alt="" />
            <img className="two_phone" src={two_phone} alt="" />
        </div>
      </div>
    </div>
  );
}
