import React from "react";
import Koleso from "./../assets/image/hourglass.png";
import Icon from "./../assets/image/Icon.png";
import shieldcheck from "./../assets/image/shieldcheck.png";
import thumbup from "./../assets/image/thumbup.png";
import AppImage from "./../assets/image/handl.png";

export default function AboutMobile() {
  return (
    <div className="container">
      <div className="about_mobile">
        <div className="about">
          <h2>
            Мы поможем сделать ваши покупки <span>быстрыми и лёгкими</span>.
          </h2>
          <p>Что вы найдёте в нашем приложении?</p>
        </div>

        <div className="features_grid">
          <div
            data-aos="fade-left" 
            data-aos-duration="1000"
            className="feature_card"
          >
            <div className="icon">
              <img src={Koleso} alt="" />
            </div>
            <h3>Быстрая доставка</h3>
            <p>Получите ваши любимые продукты в течение часа.</p>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1200"
            data-aos-delay="200" 
            className="feature_card"
          >
            <div className="icon">
              <img src={Icon} alt="" />
            </div>
            <h3>Широкий ассортимент</h3>
            <p>Выбирайте из тысячи товаров от лучших производителей.</p>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1400"
            data-aos-delay="400" 
            className="feature_card cars"
          >
            <div className="icon">
              <img src={shieldcheck} alt="" />
            </div>
            <h3>Качество гарантировано</h3>
            <p>Мы доставляем только свежие и проверенные продукты.</p>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1600"
            data-aos-delay="600" 
            className="feature_card cars"
          >
            <div className="icon">
              <img src={thumbup} alt="" />
            </div>
            <h3>Удобный интерфейс</h3>
            <p>Заказывайте продукты в несколько кликов, где бы вы ни были.</p>
          </div>
        </div>

        <div className="about_app_container">
          <div className="about_text">
            <h2>О приложении</h2>
            <p>
              Наше приложение — это ваш надёжный помощник для заказа продуктов,
              когда вам нужно быстро и удобно. С интуитивно понятным интерфейсом
              и широким ассортиментом, вы можете легко найти всё, что вам нужно,
              будь то свежие овощи, молочные продукты или вкусные готовые блюда.
              Мы стремимся сделать процесс заказа простым и приятным — выбирайте,
              добавляйте в корзину и наслаждайтесь качественными продуктами,
              доставленными прямо к вам домой.
            </p>
          </div>
          <div     data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="600" className="about_image">
            <img src={AppImage} alt="Приложение" />
          </div>
        </div>
      </div>
    </div>
  );
}
