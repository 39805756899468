import React from "react";
import monile from "./../assets/image/monile.png";
import phone1 from "./../assets/image/phone1.png";
import phone2 from "./../assets/image/phone2.png";
import QR_Code from "./../assets/image/QR_Code.png";
import googleplay from "./../assets/image/googleplay.png";
import AppStore from "./../assets/image/AppStore.png";
export default function Funk() {
  return (
    <div className="container">
      <div className="funkshion">
        <div className="funk">
          <h1>Удобный функционал и приятный дизайн</h1>
          <p>
            Приложение с интуитивно легким и приятным <br /> дизайном,
            ориентированный на пользователя.
          </p>
        </div>

        <div className="relative">
          <div className="phones">
            <img
              data-aos="fade-up"
              data-aos-duration="5000"
              className="monile"
              src={phone1}
              alt=""
            />
            <img
              data-aos="fade-up"
              data-aos-duration="5200"
              data-aos-delay="200"
              className="monile"
              src={phone2}
              alt=""
            />
            <img
              data-aos="fade-up"
              data-aos-duration="5400"
              data-aos-delay="400"
              className="monile"
              src={monile}
              alt=""
            />
          </div>
          <div className="applecation">
            <div className="pro">
              <h1>Мобильное приложение</h1>
              <p>
                Скачайте приложение и сделайте ваш первый заказ прямо сейчас с
                бесплатной доставкой!
              </p>
            </div>
            <img className="qr" src={QR_Code} alt="" />
            <div className="mobile none">
              <img src={googleplay} alt="" />
              <img src={AppStore} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
